import type { LocalizableString } from "@virtual-repetitions/lib-components";
import { Localize } from "@virtual-repetitions/lib-components";
import React, { ReactNode } from "react";
import type { DialogProps } from "./Dialog";
import { Dialog, DialogActions, DialogBody, DialogTitle } from "./Dialog";

export interface ControlledConfirmDialogProps extends DialogProps {
  cancelText?: LocalizableString;
  children: ReactNode;
  confirmDisabled?: boolean;
  confirmText?: LocalizableString;
  onConfirm(args: any | any[]): any;
  title: ReactNode;
}

export const ControlledConfirmDialog = ({
  cancelText = "cancel",
  children,
  confirmDisabled = false,
  confirmText = "confirm",
  onConfirm,
  onDismiss,
  title,
  ...others
}: ControlledConfirmDialogProps) => (
  <Dialog onDismiss={onDismiss} {...others}>
    <DialogTitle className="mb-3 text-center">{title}</DialogTitle>
    <DialogBody>{children}</DialogBody>
    <DialogActions className="mt-3 flex justify-around">
      <button className="btn btn-outline" onClick={onDismiss}>
        <Localize string={cancelText} transform="startCase" />
      </button>
      <button
        className="btn btn-primary"
        onClick={onConfirm}
        disabled={confirmDisabled}
      >
        <Localize string={confirmText} transform="startCase" />
      </button>
    </DialogActions>
  </Dialog>
);

export default ControlledConfirmDialog;
